/*=== Media Query ===*/
/* Reset CSS */
@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap');
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Karla', sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #1f1f1f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-weight: 700;
}

a,
span {
  display: inline-block;
}

a {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: inherit;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
}

img {
  max-width: 100%;
}

p {
  line-height: 26px;
  font-size: 15px;
}

p.lg {
  font-size: 16px;
}

p.sm {
  font-size: 12px;
}

@media (min-width: 768px) {
  p {
    line-height: 32px;
  }
}

.btn:focus,
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

button:focus {
  outline: none;
}

.card {
  border: none;
  border-radius: 0;
}

/* Slick Slider Resets */
.slick-slide:focus {
  outline: none;
}

.slick-slide:focus a:focus {
  outline: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Common Classes */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* Typography */
.title-large {
  font-size: 38px;
}

.title-mid {
  font-size: 21px;
}

/* Background Image */
.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Buttons */
.btn {
  font-family: 'Karla', sans-serif;
  letter-spacing: -1.12px;
  font-weight: 700;
  font-size: 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
}

.btn--primary {
  background-color: #258aff;
  color: #fff;
}

.btn--primary:hover {
  background-color: rgba(37, 138, 255, 0.8);
  color: #fff;
}

.btn-outlined--primary {
  background-color: transparent;
  color: #258aff;
  border: 2px solid #258aff;
}

.btn-outlined--primary:hover {
  background-color: #258aff;
  color: #fff;
}

.btn-outlined--white {
  color: #fff;
  border: 2px solid #fff;
}

.btn-outlined--white:hover {
  background-color: #fff;
  color: #000;
  border-color: #fff;
}

.btn-height-60 {
  height: 60px;
  padding-right: 30px;
  padding-left: 30px;
}

/* Go from zero to full opacity */
@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Margin  */
.mb-d-30 {
  margin-bottom: -30px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mb--65 {
  margin-bottom: 65px;
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
/*# sourceMappingURL=settings.css.map */
