.rectangle-box {
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  .MuiIcon-root {
    font-size: 18px;
  }
}

.satisfaction-empty {
  -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
  filter: grayscale(100%);
}
